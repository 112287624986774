import React from "react";

import "../../styles/global.scss";

import Layout from "../layouts/Layout";
import BannerSection from "../partials/BannerSection";
import CategoriesSection from "../partials/CategoriesSection";
import NetworkSection from "../partials/NetworkSection";
import CaseStudiesSection from "../partials/CaseStudiesSection";
import PlatformSection from "../partials/PlatformSection";
import PartnersSection from "../partials/PartnersSection";
import ContactSection from "../partials/ContactSection";
import { partnerLogos } from "../pages/index";

import { useMediaQuery } from "../utils/hooks";

const PartnersPage = props => {
  const {
    pageContext: { caseStudies },
  } = props;

  const isMobile = useMediaQuery("(max-width: 575px)");

  const ctaButtons = [
    { label: "Start today", href: "/book-a-demo" },
    { label: "Schedule a demo", href: "/book-a-demo" },
  ];

  return (
    <Layout
      pageTitle="Partners | Brandcrush"
      ogImage="/images/seo/partner.jpg"
      ogDescription="End-to-end software powering businesses around the world to monetize and maximize their owned media assets through retail media, activations and commercial partnerships."
    >
      <BannerSection
        theme="navy"
        heading="Unlock and grow media revenue with one simple platform"
        body="Streamline and scale media bookings for your shopper marketing, retail media and partnerships."
        height={isMobile ? 25.0 : 30.25}
        marginBottom={isMobile ? 13.938 : 10.0}
        backgroundImg="/images/cloud-partner.svg"
        backgroundImgX={isMobile ? -4.0 : -1.7}
        backgroundImgY={isMobile ? -10.77 : -2.0}
        backgroundImgWidth={isMobile ? 20.563 : 48.138}
        backgroundImgFlipX={isMobile}
        foregroundImg={
          isMobile
            ? "/images/computer-asset-potential-mobile@3x.png"
            : "/images/computer-asset-potential@3x.png"
        }
        foregroundImgX={isMobile ? -0.6 : 5.625}
        foregroundImgY={isMobile ? -9.548 : 0.0}
        foregroundImgWidth={isMobile ? 17.5 : 29.875}
        foregroundImgFlipX={isMobile}
        ctaButtons={[
          { ...ctaButtons[0], color: "cerise" },
          { ...ctaButtons[1], color: "white" },
        ]}
      />
      <CategoriesSection
        copy="Connect brands with your audience through smart activations"
        title1="In-Store"
      />
      <NetworkSection
        heading="Join a growing network of businesses unlocking and growing media revenue"
        logos={partnerLogos(isMobile)}
        height="5.0"
        autoplay
        ctaButtons={ctaButtons}
      />
      <CaseStudiesSection caseStudies={caseStudies} />
      <PlatformSection
        heading="Seamlessly manage your media with a dedicated end-to-end retail media and partnerships platform"
        topHeading="Maximize your media sales"
        topCopy="Grow and scale revenue with custom platform tools"
        topGraphics={[
          {
            image: "/images/window@3x.png",
            x: 0,
            y: 0,
            delay: 0.0,
            height: isMobile ? 14.75 : 32.5,
          },
          {
            image: "/images/sales-graph@3x.png",
            x: isMobile ? 1.406 : 3.125,
            y: isMobile ? 4.404 : 9.688,
            delay: 0.2,
            width: isMobile ? 15.938 : 35.063,
          },
          {
            image: "/images/sales-stats@3x.png",
            x: isMobile ? 1.406 : 3.125,
            y: isMobile ? 1.847 : 4.063,
            delay: 0.4,
            width: isMobile ? 15.938 : 35.063,
          },
        ]}
        middleHeading="Streamline your activations"
        middleCopy="Manage your activations with booking, inventory and campaign workflows"
        middleGraphics={[
          {
            image: "/images/window@3x.png",
            x: 0,
            y: 0,
            delay: 0.0,
            height: isMobile ? 14.75 : 32.5,
          },
          {
            image: "/images/activations-logo@3x.png",
            x: isMobile ? 1.42 : 3.125,
            y: isMobile ? 10.417 : 22.917,
            delay: 0.2,
            height: isMobile ? 2.273 : 5.0,
          },
          {
            image: "/images/activations-name@3x.png",
            x: isMobile ? 4.375 : 9.625,
            y: isMobile ? 11.364 : 25.0,
            delay: 0.2,
            height: isMobile ? 0.369 : 0.8125,
          },
          {
            image: "/images/activations-table@3x.png",
            x: isMobile ? 1.932 : 4.25,
            y: isMobile ? 2.178 : 4.792,
            delay: 0.4,
            height: isMobile ? 7.188 : 15.8125,
          },
        ]}
        bottomHeading="Simplify your payments"
        bottomCopy="Full vendor payment management, payments and collections"
        bottomGraphics={[
          {
            image: "/images/window@3x.png",
            x: 0,
            y: 0,
            delay: 0.0,
            height: isMobile ? 14.75 : 32.5,
          },
          {
            image: "/images/payments-summary@3x.png",
            x: isMobile ? 1.136 : 2.5,
            y: isMobile ? 4.318 : 9.5,
            delay: 0.2,
            width: isMobile ? 7.67 : 16.875,
          },
          {
            image: "/images/payments-table@3x.png",
            x: isMobile ? 9.943 : 21.875,
            y: isMobile ? 2.159 : 4.75,
            delay: 0.4,
            width: isMobile ? 7.67 : 16.875,
          },
          {
            image: "/images/payments-button@3x.png",
            x: isMobile ? 1.932 : 4.25,
            y: isMobile ? 2.159 : 4.75,
            delay: 0.6,
            width: isMobile ? 6.051 : 13.313,
          },
        ]}
        ctaButtons={ctaButtons}
        theme="navy"
      />
      <ContactSection
        heading="Your media, your platform"
        body="Drive your media sales with a fully customized and branded media platform, integrated with your own website."
        buttonLabel="Solutions for your business"
        backgroundImg="/images/cloud-navy.svg"
        foregroundImg="/images/your-business.svg"
        foregroundImgX={isMobile ? 0.0 : -2.6}
        foregroundImgY={isMobile ? -2.5 : -3.563}
        foregroundImgWidth={isMobile ? 20 : 51.0}
        height={isMobile ? 27.125 : 27.25}
        href="/brandcrush-for-business"
      />
      <PartnersSection ctaButtons={ctaButtons} />
    </Layout>
  );
};

export default PartnersPage;
