import React from "react";

import {
  partners,
  partnersContainer,
  partnersGraphic,
  checklist,
  buttonContainer,
} from "./PartnersSection.module.scss";

import AnimatedSvg from "../components/AnimatedSvg";
import Checklist from "../partials/Checklist";
import CTAButtons from "../partials/CTAButtons";

import { useMediaQuery } from "../utils/hooks";

const PartnersSection = props => {
  const { signupHref, ctaButtons } = props;

  const isMobile = useMediaQuery("(max-width: 575px)");

  return (
    <section className={partners}>
      <h2>Partner with the world's leading brands and agencies</h2>
      <div className={partnersContainer}>
        <AnimatedSvg
          className={partnersGraphic}
          graphics={[
            {
              image: "/images/window@3x.png",
              x: 0,
              y: 0,
              delay: 0.0,
              height: isMobile ? 14.75 : 32.5,
            },
            {
              image: "/images/brands@3x.png",
              x: isMobile ? 1.102 : 2.425,
              y: isMobile ? 2.245 : 4.938,
              delay: 0.2,
              height: isMobile ? 9.091 : 20.0,
            },
          ]}
        />
        <Checklist
          className={checklist}
          items={[
            <>
              Easily onboard brands &amp; agencies
              <br />
              onto your media platform
            </>,
            <>
              Full control to approve/decline
              <br />
              campaign requests
            </>,
            <>
              Propose tailored campaigns to
              <br />
              brands to drive upsell
            </>,
            <>
              Opt in to promote your media on the
              <br />
              Brandcrush marketplace
            </>,
          ]}
        />
      </div>
      <CTAButtons className={buttonContainer} buttons={ctaButtons} />
    </section>
  );
};

export default PartnersSection;
